import React, { Component, Fragment } from 'react'
import SEO from '@/components/SEO/SEO'

export default class NotFoundPage extends Component {
	render() {
		const { location } = this.props
		return (
			<Fragment>
				<SEO title="404" url={location.href} />
				<div className="wrapper py-4 md:py-8 text-center min-h-404 flex flex-col items-center justify-center">
					<h1
						className="glitch glitch--404 inline-block text-xlh-fluid font-light mb-4"
						data-text="404"
					>
						<span>404</span>
					</h1>
					<p className="text-lg-fluid">
						uh oh! the page you are looking for no longer exists
					</p>
				</div>
			</Fragment>
		)
	}
}
